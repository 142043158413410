<template>
  <section>
      <span class="d-block f16 text-center mb-4">{{exam.name}}</span>

      <span class="f13 grey--text text--darken-1 d-flex flex-row align-items-center justify-content-center mt-2" v-if="examProp.createDate">
        {{ $t('Date of prescription:') }} 
        <i class="far fa-calendar-alt ms-2 me-1"/>
        {{ examProp.createDate | moment("dddd , jD jMMMM jYYYY") }} 
        <i class="far fa-clock ms-2 me-1"/>
        {{ examProp.createDate | moment("HH:MM") }} 
      </span>


      <v-card class="d-flex align-items-center my-2" flat v-if="showDoctorInfo">
        <v-avatar class="ms-0 me-2 my-2 rounded-lg" size="96">
          <v-img :lazy-src="require('@/assets/images/profile-circle.png')" :src="
            doctor.pic != null && doctor.pic != ''
              ? doctor.pic
              : require('@/assets/images/profile.jpg')
          "></v-img>
        </v-avatar>
        <div class="ms-4 me-0 d-flex flex-column">
          <span class="f14 font-weight-bold">
            {{ doctor.firstname }} {{ doctor.lastname }}
          </span>

          <span class="mt-1 f13 primary--text text--darken-1">
            {{ doctor.clinic.name }}
          </span>

          <span v-if="doctor.specialties != null && doctor.specialties.length > 0" class="mt-1 f13 grey--text text--darken-1">
            {{ doctor.specialties[0].value }}
          </span>
        </div>
      </v-card>

<!--    <v-img-->
<!--        width="100%"-->
<!--        max-width="350"-->
<!--        class="my-3 mx-auto"-->
<!--        :src="exam.introImage"-->
<!--        :lazy-src="require('@/assets/images/exam-intro.png')"-->
<!--        :dense="true"-->
<!--    />-->

    <img src="@/assets/images/exam-intro.png" width="100%" max-width="350" />
      
      <div class="grey lighten-4 rounded-lg text-dark px-4 py-2 mb-8">
        <span class="f15 fw-bold d-block">{{$t('Description')}}</span>
        <p class="f14 pa-2 m-0" v-html="exam.userDescription"></p>
      </div>

    <v-footer
        app
        paddless
        bordered
        color="white"
        class="pb-2 pt-0"
        min-height="130"
      >
      <div class=" border-top w-100">
        <v-container fluid class="">
          <div v-if="exam.examAssignStatus == 'PAID' || exam.examAssignStatus == 'FREE'" class="mt-2">
            <span class="f15 fw-bold d-block text-center py-2 grey lighten-4 rounded-lg mb-2" v-if="exam.examAssignStatus == 'FREE'">{{$t('Cost')}} : {{$t('Free')}}</span>
            <span class="f15 fw-bold d-block text-center py-2 grey lighten-4 rounded-lg mb-2" v-else>{{$t('Cost')}} : {{$t('Paid')}}</span>
            <v-btn color="primary" large block class="rounded-lg" @click="handleNextPage()">
              {{$t('Next page')}}
            </v-btn>
          </div>
          <div v-else-if="exam.examAssignStatus == 'PAYMENT_PENDING'"  class="mt-2">
            <span class="f15 fw-bold d-block text-center py-2 grey lighten-4 rounded-lg mb-2">{{$t('Cost')}} : {{ (examProp.eprice || 0) | VMask(currencyMask)}} {{$t('RIAL')}}</span>
            <v-btn color="primary" large block class="rounded-lg" @click="handlePay()">
              {{$t('Pay')}}
            </v-btn>
          </div>
          <div v-else-if="exam.examAssignStatus == 'ANSWERED'"  class="mt-2">
            <div v-if="exam.type == 'PUBLIC'">
              <span class="f15 fw-bold d-block text-center py-2 grey lighten-4 rounded-lg mb-2">{{$t('Answered')}}</span>
              <v-btn color="primary" large block class="rounded-lg" @click="handleViewResult()">
                {{$t('View result')}}
              </v-btn>
            </div>
            <div v-else>
              <v-btn color="primary" large block class="rounded-lg">
                {{$t('Answered')}}
              </v-btn>
            </div>
          </div>
        </v-container>
      </div>
    </v-footer>
  </section>
</template>

<script>
import apiService from "@/http/ApiService";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";
moment.locale("fa", fa);
moment.loadPersian();
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

  const currencyMask = createNumberMask({
    prefix: '',
    allowDecimal: false,
    includeThousandsSeparator: true,
    allowNegative: false,
  });


export default {
  data() {
    return {
      currencyMask,
      
      exam: {},
      examProp: {},
      doctor: {
        clinic: {},
        specialties: [ 
          {
            value: '',
          }
        ]
      },
      showDoctorInfo: false,
    }
  },
  methods: {
    getExamDetails(code) {
      apiService.getExamDetails(code)
      .then((response) => {
        this.exam = response.data;

        if (this.exam.drUsername) {
          this.showDoctorInfo = true;
          this.getDoctorInfoByMobile(this.exam.drUsername);
        }
        else {
          this.showDoctorInfo = false;
        }
      })
      .catch((err) => {
        console.log(err);
        this.$router.go(-1);
      })
    },
    getExamTodoList(code) {
      apiService.getExamTodoList(code)
      .then((response) => {
        this.examQuestionsListState = response.data
          const nextQuestion = this.examQuestionsListState.reduce((prev, curr) => prev.questionNumber < curr.questionNumber && !prev.selectedId ? prev : curr)
          this.nextQuestionNumber = nextQuestion.questionNumber
      })
      .catch((err) => {
        console.log(err);
        this.$router.go(-1);
      })
    },
    getDoctorInfoByMobile(drUsername) {
      apiService.getDoctorInfoByMobile(drUsername)
      .then((response) => {
        this.doctor = response.data;
      })
      .catch((err) => {
        console.log(err);
        this.$router.go(-1);
      })
    },
    handleNextPage(){
      this.startExamLoading = true
      this.$router.push({ name: 'ExamQuestion', params: { questionNumber: this.nextQuestionNumber } })
      this.startExamLoading = undefined
    },
    handleViewResult(){
      this.$router.push({ name: 'ExamResult' })
    },
    handlePay(){
      this.getClientAccountBalance();
    },
    getClientAccountBalance() {
      apiService.getClientAccountBalance()
        .then((response) => {
          var accountBalance = response.data;
          if (accountBalance.cash >= (this.examProp.eprice || 0)) {
            var paymentDTO = {
              drUsername: this.examProp.drMobile,
              examAssignCode: this.examProp.code,
              finalAmount: (this.examProp.eprice || 0),
              originalAmount: (this.examProp.eprice || 0),
              paymentReason: "EXAM",
              reason: "EXAM"
            };

            apiService.paymentWithWallet(paymentDTO)
            .then((response) => {
              if(response.data.code == "0") {
                this.$swal({
                  icon: "success",
                  title: this.$t("Success"),
                  text: this.$t("The exam fee has been paid successfully"),
                  confirmButtonText: this.$t('Continue'),
                })
                .then((result) => {
                  if (result.isConfirmed) {
                    this.handleNextPage();
                  }
                });
              }
              else {
                this.$swal({
                  icon: "warning",
                  text: this.$t("Error in paying the exam fee"),
                  confirmButtonText: this.$t('OK'),
                });
              }
            })
            .catch((err) => {
              console.log(err);
              this.$swal({
                icon: "warning",
                text: this.$t("Error in paying the exam fee"),
                confirmButtonText: this.$t('OK'),
              });
            });
          }
          else {
            this.$swal({
            icon: "warning",
            title: this.$t("Wallet balance is not enough"),
            text: this.$t("Your wallet balance is not enough. Please increase the inventory"),
            confirmButtonText: this.$t('Increase wallet balance'),
            showDenyButton: true,
            denyButtonText: this.$t('Cancel')
            })
            .then((result) => {
              if (result.isConfirmed) {
                this.$router.push('/c/profile/wallet');
              }
            });
          }
          
        })
        .catch((err) => {
          console.log(err);
          this.$swal({
            icon: "warning",
            text: this.$t("Error receiving wallet information"),
            confirmButtonText: this.$t('OK'),
          });
        });
    },
  },
  computed: {
    examToDoState: {
      get () {
        return this.$store.getters['exam/getExamToDo']
      },
      set (val) {
        this.$store.commit('exam/updateExamToDo', val)
      }
    },
    examQuestionsListState: {
      get () {
        return this.$store.getters['exam/getExamQuestionsList']
      },
      set (val) {
        this.$store.commit('exam/updateExamQuestionsList', val)
      }
    }
  },
  beforeMount() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);

  },
  mounted(){
    if (this.$route.params.obj) {
      this.examProp = this.$route.params.obj;
    }
    else {
      this.$router.go(-1);
    }
    this.getExamDetails(this.$route.params.code);
    this.getExamTodoList(this.$route.params.code);
  }
}
</script>
